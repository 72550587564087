import React from "react";
// import FormBuilder from "../forms/FormBuilder";
import {FormBuilder} from "@jaguardesignstudio/silentgrowl-form-builder-react";
import RegistrationPageSignifiers from "./RegistrationPageSignifiers";
// import RegistrationTicketField from "./RegistrationTicketField";
import RegistrationCustomButton from "./RegistrationCustomButton";
import RegistrationRadioField from "./RegistrationRadioField";
import RegistrationDropdownField from "./RegistrationDropdownField";
import RegistrationCheckboxField from "./RegistrationCheckboxField";

class RegistrationFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('reg submit complete');
    console.log(json);
  }

  // getDateFromValue(value) {
  //   console.log("Date Metadata Value: ", value)
  //   switch(value) {
  //     case "day1":
  //       return "Thursday, June 13, 2024"
  //     case "day2":
  //       return "Friday, June 14, 2024"
  //     case "day3":
  //       return "Saturday, June 15, 2024"
  //     case "day4":
  //       return "Sunday, June 16, 2024"
  //   }
  // }

  // getCheckInLocationFromValue(value) {
  //   console.log("Location Metadata Value: ", value)
  //   switch(value) {
  //     case "pinehurst":
  //       return "The Carolina Hotel | Pinehurst, NC"
  //     case "cary":
  //       return "The Umstead Hotel | Cary, NC"
  //   }
  // }

  getReplacableStrings() {
    const { participant } = this.props;
    return [
      { key: '*|EMAIL|*', value: participant.email },
    ]
    // [ { key: '*|DATE|*', value: this.getDateFromValue(participant.metadata[0].value) },
    // { key: '*|CHECKIN|*', value: this.getCheckInLocationFromValue(participant.metadata[12].value) },]
  }

  render() {
    const { ticket_info, participant, form } = this.props;
    return (
      <FormBuilder
        key="registration-form"
        components={{
          // ticketfield: RegistrationTicketField,
          pageSignifiers: RegistrationPageSignifiers,
          buttonCustom: RegistrationCustomButton,
          radiofield: RegistrationRadioField,
          selectfield: RegistrationDropdownField,
          checkboxfield: RegistrationCheckboxField
        }}
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/registration'}
        submitCallback={this.callback}
        type={'registration'}
        hide_test={false}
        // use_legacy_ticketing={true}
      />
    );
  }
}

export default RegistrationFormWrapper;
