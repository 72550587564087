import Cookies from "universal-cookie";

export function submitRegistration(values, state, component, url, showTicketing=true, callback=()=>{}) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  let form_data = new FormData();
  component.setState({
    loading: true,
  })

  for ( var key in values['participant'] ) {
    form_data.append(`participant[${key}]`, values['participant'][key]);
  }
  for ( var key in values['participant']['metadata'] ) {
    form_data.append(`metadata[${key}]`, values['participant']['metadata'][key])
  }

  // Ticketing hack: programname holds the ticket count slug
  if (showTicketing) {
    let ticketCount = 1;
    if (values['participant']['metadata']['bringingguest']) {
      ticketCount = 2;
    }
    form_data.append(`metadata[${values['participant']['metadata']['programname']}]`, ticketCount);
  }

  fetch(url, {
    method: `POST`,
    redirect: "manual",
    body: form_data,
    headers: {
      "X-CSRF-Token": token,
    }
  }).then(response => {
    try {
      return response.json();
    }
    catch(e) {
      component.setState({
        loading: false,
        error: "An error occurred. Please try again later."
      })
    }
  })
  .then(json => {
    if (json.error == null) {
      window.scrollTo(0,0);
      component.setState({
        page: state.page + 1,
        loading: false,
      })
      callback(json);
    } else {
      if (json.error == "reg-closed") {
        location.href = 'pages/closed';
      }
      component.setState({
        fullPageError: json.error,
        loading: false,
      })
    }
  });
}

export function cancelRegistration(email, cancelled_by, url, component, callback=()=>{}) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  component.setState({
    loading: true,
  })
  let dateNow = new Date();
  let form_data = new FormData();
  form_data.append('email', email);
  form_data.append('cancelled_by', cancelled_by);
  form_data.append('cancelled_at', dateNow.toISOString());
  fetch(url, {
    method: `POST`,
    redirect: "manual",
    body: form_data,
    headers: {
      "X-CSRF-Token": token,
    }
  }).then(response => {
    try {
      return response.json();
    }
    catch(e) {
      console.log(e);
      component.setState({
        loading: false,
        error: "An error occurred. Please try again later."
      })
    }
  }).then(json => {
    if (json.error == null) {
      callback(json)
      component.setState({
        loading: false
      })
    } else {
      component.setState({
        fullPageError: json.error,
        loading: false,
      })
    }
  });
}