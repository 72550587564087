import React from "react";
import PropTypes from "prop-types";
import ReportBubble from "./ReportBubble"
import EmailSectionStats from "./mail/EmailSectionStats"
import EmailSectionTile from "./EmailSectionTile";
import { uuidv4 } from '@utility/FileUtils'
import DefaultTooltip from "@layout/tooltips/DefaultTooltip"

class EmailSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: uuidv4()
    }
  }

  renderToolTips() {
    return (
      <>
      <DefaultTooltip
        tooltipId={this.getTooltipId()+"download-report"}
      >
        Click to download full report including CC and BCC info
      </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"open-rate"}
        >
          Opens count only invitees, no CCs or BCCs
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"opened"}
        >
          Opens count only invitees, no CCs or BCCs
        </DefaultTooltip>

        <DefaultTooltip
          tooltipId={this.getTooltipId()+"click-rate"}
        >
          Clicks count only invitees, no CCs or BCCs
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"clicked"}
        >
          Clicks count only invitees, no CCs or BCCs
        </DefaultTooltip>

        <DefaultTooltip
          tooltipId={this.getTooltipId()+"bounce-rate"}
        >
          Bounces count only invitees, no CCs or BCCs
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"bounced"}
        >
          Bounces count only invitees, no CCs or BCCs
        </DefaultTooltip>

        <DefaultTooltip
          tooltipId={this.getTooltipId()+"registered"}
        >
          Conversion number from only this type of email
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={this.getTooltipId()+"declined"}
        >
          Conversion number from only this type of email
        </DefaultTooltip>
      </>
    )

  }

  filterMainRecipients(activities, mainRecipients) {
    return activities.filter(x=> mainRecipients.indexOf(x.recipient) != -1)
  }

  getTooltipId() {
    return "sg-admin-email-section-tooltip-"+this.state.id+"-"
  }

  renderStatistic(label, value, opts = {}) {
    const style = {}
    if(opts.color){
      style.color = opts.color
    }
    return (
      <div
        data-tip
        data-for={this.getTooltipId()+(opts.id||"")}
        className="sg-admin-email-section-statistic-section"
      >
        <div className="sg-admin-email-section-statistic"
          style={style}
        >
          {value}
          <div className="sg-admin-email-section-statistic-label">
            {label}
            {opts.report ? (
              <div className="sg-admin-panel-statistic-download">
                <a data-tip data-for={this.getTooltipId()+"download-report"} href={opts.report} >
                  <img src="/images/admin/blue-download-report.png"/>
                </a>
              </div>
            ):""}
          </div>
        </div>
      </div>
    )
  }

  getEmailFullReportUrl(key) {
    return `/admin/reports/full_activity_report_by_tag?tag=${key}`;
  }

  renderEmails(id, name, email) {
    const { bubbleLabel, emailList, declines, registrants, supressRegistration} = this.props;
    const { activity } = email;
    const recipients = activity.map(x=>x.emails).flat()
    const mainRecipients = recipients.map(x=>x.email);
    const cc_emails = recipients.map(x=>(x.cc_list||"").split(",")).flat();
    const total_recipients = mainRecipients.concat(cc_emails).length

    const activities = activity.map(x=>x.activity).flat()
    const clicks = this.filterMainRecipients(
      activities.filter(x=>x.activity_type=="clicked"),
      mainRecipients
    ).map(x=>x.recipient).filter((x,i,t)=> t.indexOf(x) == i);

    const mainOpens = this.filterMainRecipients(
      activities.filter(x=>x.activity_type=="opened"),
      mainRecipients
    ).map(x=>x.recipient).filter((x,i,t)=> t.indexOf(x) == i);

    const mainBounces = this.filterMainRecipients(
      activities.filter(x=>x.activity_type=="bounced"),
      mainRecipients
    ).map(x=>x.recipient).filter((x,i,t)=> t.indexOf(x) == i);
    // const newRegistrants = registrants.filter(x=> mainRecipients.indexOf(x.email) != -1)
    // const newDeclines = declines.filter(x=> mainRecipients.indexOf(x.email) != -1)
    // const mainOpenRateCopy = mainRecipients.length > 0 ? ((mainOpens.length/mainRecipients.length)*100).toFixed(0)+"%" : "N/A"
    // const clickRateCopy = mainRecipients.length > 0 ? ((clicks.length/mainRecipients.length)*100).toFixed(0)+"%" : "N/A"
    // const bounceRateCopy = mainRecipients.length > 0 ? ((mainBounces.length/mainRecipients.length)*100).toFixed(0)+"%" : "N/A"

    return (
      <tr>
        <td>{name}</td>
        <td><EmailSectionTile count={mainRecipients.length} reportUrl={`/admin/reports/mailing_list_report_by_tag?tag=${email.id}`} color='darkblue' /></td>
        <td><EmailSectionTile count={mainOpens.length} reportUrl={`/admin/reports/activity_report_by_tag?tag=${email.id}&type=opened`} color='lightblue' /></td>
        <td><EmailSectionTile count={clicks.length} reportUrl={`/admin/reports/activity_report_by_tag?tag=${email.id}&type=clicked`} color='yellow' /></td>
        <td><EmailSectionTile count={mainBounces.length} reportUrl={`/admin/reports/activity_report_by_tag?tag=${email.id}&type=bounced`} color='orange' /></td>
        <td>
          <a href={this.getEmailFullReportUrl(email.id)} target="_blank">
            <div className="sg-email-section-tile-report" style={{position: 'static', margin: 'auto'}}>
              <img src="/images/admin/file-arrow-down.png" />
            </div>
          </a>
        </td>
      </tr>
    )
  }

  render() {
    const { email, emailList } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12">
          <table className="sg-email-section-table">
            <tr>
              <th>Email</th>
              <th>Recipients</th>
              <th>Opened</th>
              <th>Clicks</th>
              <th>Bounced</th>
              <th>Full Send Report</th>
            </tr>
            {
              emailList.map(e =>
                this.renderEmails(e.id, e.name, email.find(x=>x.id==e.id))
              )
            }
          </table>
        </div>
      </div>
    );
  }
}

export default EmailSection;
