import React, { Component } from 'react';

export default class RegistrationCheckboxField extends Component {

  translateCSS(css) {
    // TODO: Implement css -> jsx styling
    let jsonCSS = {}
    if (css !== '') {
      try {
        jsonCSS = JSON.parse(css)
      } catch (e) {
        return {}
      }
    }
    return jsonCSS;
  }

  getFormikValue(attribute_id, formikProps) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    if (this.isMetaDataField(attribute_id)) {
      // Metadata Field
      let formikPropsParticipant = formikProps.values["participant"];
      let value = formikPropsParticipant["metadata"][splitQuestionId[2]];
      try {
        // console.log(value);
        newvalue = value.split(',');
        value = newvalue;
      } catch {
        // console.log('not an array')
      }
      return value;
    } else {
      // Participant Field
      let formikPropsParticipant = formikProps.values["participant"];
      return formikPropsParticipant[splitQuestionId[1]];
    }
  }

  splitObjectField(attribute_id) {
    return attribute_id.split('.');
  }

  isMetaDataField(attribute_id) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    return splitQuestionId.length > 2
  }

  setFormikValue(value, attribute_id, formikProps) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    if (this.isMetaDataField(attribute_id)) {
      // Metadata Field
      let formikPropsParticipant = formikProps.values["participant"];
      formikPropsParticipant["metadata"][splitQuestionId[2]] = value;
      formikProps.setFieldValue('participant', formikPropsParticipant);
    } else {
      // Participant Field
      let formikPropsParticipant = formikProps.values["participant"];
      formikPropsParticipant[splitQuestionId[1]] = value;
      formikProps.setFieldValue('participant', formikPropsParticipant);
    }
  }

  renderQuestionLabel(question) {
    return (
      <div className="label-wrapper">
        <label className={`label-english ${question.required ? 'required':''}`}> {question.label} </label>
      </div>
    );
  }

  render() {
    const { type, formikProps, question, erroring } = this.props;
    let value = this.getFormikValue(question.attribute_id, formikProps, type);
    let checkboxOptions = question.options.map(option =>
      <div
        className={`col-xs-12 col-md-3 ${question.custom_class}`}
        style={this.translateCSS(question.custom_css)}
        key={`checkbox-question-${question.gid}-option-${option.value}`}
      >
        <div className="radio-option-wrapper">
          <img
            src={ value !== undefined && value.includes(option.value) ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
            style={{cursor:"pointer", marginBottom: "7px"}}
            onClick={(e) => {
              if (value !== undefined && value !== '') {
                console.log(value);
                console.log(option);

                let valueArray = value.split(',');
                let index = valueArray.indexOf(option.value);

                if (index !== -1) {
                  valueArray.splice(index, 1);
                } else {
                  valueArray.push(option.value);
                }
                this.setFormikValue(valueArray.join(','), question.attribute_id, formikProps, type)
              } else {
                this.setFormikValue(option.value, question.attribute_id, formikProps, type);
              }
            }}
          />
          <div className="radio-option-label">{option.label}</div>
        </div>
      </div>
    );
    return(
      <>
        <div className={`col-xs-12 ${question.custom_class}`} style={this.translateCSS(question.custom_css)}>
          {this.renderQuestionLabel(question)}
        </div>
        {checkboxOptions}
        <div className={`col-xs-12`} />
        {
          erroring ?
          <div className="col-xs-12">
            <div className="registration-field-error">
              <>Please select an option to continue.</>
            </div>
          </div>
          :
          <></>
        }
      </>
    )
  }
}
