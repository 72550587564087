import React from "react";
// import FormBuilder from "../forms/FormBuilder";
import {FormBuilder} from "@jaguardesignstudio/silentgrowl-form-builder-react";
import RegistrationTicketField from "../registration/RegistrationTicketField";
import RegistrationCustomButton from "../registration/RegistrationCustomButton";

class AMRegistrationFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('am reg submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    return [
      {key: '*|EMAIL|*', value: 'participant.email', from: 'formik_values' }
    ]
  }

  render() {
    const { ticket_info, participant, form } = this.props;
    return (
      <FormBuilder
        key="registration-form"
        components={{
          ticketfield: RegistrationTicketField,
          buttonCustom: RegistrationCustomButton
        }}
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/ams'}
        submitCallback={this.callback}
        type={'registration'}
        hide_test={false}
      />
    );
  }
}

export default AMRegistrationFormWrapper;
