import Cookies from "universal-cookie";

export function submitSurvey(values, state, component, gid) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  let form_data = new FormData();
  component.setState({
    loading: true,
  })

  for ( var key in values['survey'] ) {
    form_data.append(`survey[${key}]`, values['survey'][key]);
  }
  form_data.append('survey_gid', gid)
  fetch(`/surveys`, {
    method: `POST`,
    redirect: "manual",
    body: form_data,
    headers: {
      "X-CSRF-Token": token,
    }
  }).then(response => {
    try {
      return response.json();
    }
    catch(e) {
      component.setState({
        loading: false,
        error: "An error occurred. Please try again later."
      })
    }
  })
  .then(json => {
    if (json.error == null) {
      window.scrollTo(0,0);
      component.setState({
        page: state.page + 1,
        loading: false,
      })
    } else {
      if (json.error == "survey-closed") {
        location.href = 'pages/closed';
      }
      component.setState({
        fullPageError: json.error,
        loading: false,
      })
    }
  });
}
