import React from "react";

class LandingPageSchedule extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }

  getStyle() {
    const { overnight } = this.props;
    let style={}
    if (overnight) {
      style={color: "#ffffff"}
    }
    return style;
  }

  renderScheduleItem(item) {
    return (
      <tr>
        <td className="landing-page-content-table-time" style={this.getStyle()} dangerouslySetInnerHTML={{__html: item.answers.times}}/>
        <td className="landing-page-content-table-info" style={this.getStyle()} dangerouslySetInnerHTML={{__html: item.answers.answer}}/>
      </tr>
    );
  }

  getFilteredItems() {
    const { cms_section_schedule, slug_to_keep } = this.props;
    if (slug_to_keep === undefined) return cms_section_schedule["items"]
    return cms_section_schedule["items"].filter(item => item.slug !== null && item.slug.includes(slug_to_keep))
  }

  render() {
    let filteredItems = this.getFilteredItems();
    let renderedScheduleItems = filteredItems.sort((a,b) => a.answers.sort_index - b.answers.sort_index).map((item, index) =>
      this.renderScheduleItem(item)
    );
    return (
      <table className="landing-page-content-table">
        {renderedScheduleItems}
      </table>
    )
  }
}

export default LandingPageSchedule;
