import React from "react";
// import "@jaguardesignstudio/silentgrowl-form-builder-react/dist/cjs/formbuilder-styles";
import {FormBuilder} from "@jaguardesignstudio/silentgrowl-form-builder-react";
import SurveyButtonCustom from "./custom_components/SurveyButtonCustom";

class SurveyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  callback(json) {
    console.log('survey submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    return []
  }

  render() {
    const { form } = this.props;
    return (
      <FormBuilder
        key="registration-form"
        components={{
          buttonCustom: SurveyButtonCustom
        }}
        ticket_info={[]}
        participant={''}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/surveys'}
        submitCallback={this.callback}
        type={'survey'}
        profile_image={''}
        hide_test={false}
      />
    );
  }
}

export default SurveyForm;
