import { getTimezoneInfo } from "../registration/CountryCodes"
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import moment from 'moment-timezone';

export function getCurrentUTC(attendee) {
  if(attendee && attendee.registration_answers) {
    const timezones = getTimezoneInfo()
    const timezone = timezones.find(x=> x.value == attendee.registration_answers["21"])
    if(timezone) {
      return timezone.utc
    }
  }
  return 'America/Los_Angeles'
}

export function getTimeZoneAbbreviation(attendee) {
  const timezone = getCurrentUTC(attendee);
  const abbr = formatToTimeZone(new Date(), 'z', {timeZone: timezone});
  return convertToStandardAbbr(timezone, abbr);
}

export function areChatroomsOpen(times) {
 const overrideDate = localStorage.getItem('dayNumber');
 if(overrideDate >= 2 || new Date() > new Date(times['day_two_start'])) {
   return true;
 }
 return false;
}

export function isWelcomeOpen(times) {
 const overrideDate = localStorage.getItem('dayNumber');
 if(overrideDate >= 2 || new Date() > new Date(times['welcome_center_open'])) {
   return true;
 }
 return false;
}

export function isPostShowOpen(times) {
 const overrideDate = localStorage.getItem('dayNumber');
 if(overrideDate >= 5 || new Date() > new Date(times['day_five_start'])) {
   return true;
 }
 return false;
}

export function showPostShowNotification(times) {
  const overrideDate = localStorage.getItem('dayNumber');
  if(overrideDate >= 7 || new Date() > new Date(times['day_seven_start'])) {
    return true;
  }
  return false;
}

export function isConnectOpen(times) {
  const overrideDate = localStorage.getItem('dayNumber');
  if(overrideDate >= 3 || new Date() > new Date(times['day_three_start'])) {
    return true;
  }
  return false;
}

export function getCurrentTime(current_user_data) {
  let now = moment().tz(getCurrentUTC(current_user_data));
  const dayNumber = localStorage.getItem('dayNumber');
  const time = localStorage.getItem('time');
  if (dayNumber !== null) {
    let daySplit = dayNumber.split('-');
    now = now.year(daySplit[0]);
    now = now.month(daySplit[1] - 1);
    now = now.date(daySplit[2]);
  }
  if (time) {
    let timeSplit = time.split(":");
    now.hours(timeSplit[0]);
    now.minutes(timeSplit[1]);
  }
  return now;
}

export function datesAreOnSameDay(current_user_data, first, second) {
  const utc = getCurrentUTC(current_user_data)

  let momentObject1 = moment(first).tz(utc);
  let momentObject2 = moment.tz(second.split('+')[0], utc);

  return (
    momentObject1.isSame(momentObject2, 'day')
  );
}

export function convertToStandardAbbr(timezone, abbr) {
  let standardAbbr = abbr;
  // Take out standard abbreviations for now
  // const offsetArray = [
  //   { offset: "-10", name: "HAST" },
  //   { offset: "-05", name: "COT" },
  //   { offset: "-04", name: "VET" },
  //   { offset: "-03", name: "AST" },
  //   { offset: "-02", name: "BRST" },
  //   { offset: "-01", name: "AZOT" },
  //   { offset: "+01", name: "WEST" },
  //   { offset: "+01", name: "WEST" },
  //   { offset: "+03", name: "TRT" },
  //   { offset: "+0330", name: "IRST" },
  //   { offset: "+04", name: "GST" },
  //   { offset: "+0430", name: "AFT" },
  //   { offset: "+05", name: "TMT" },
  //   { offset: "+0545", name: "NPT" },
  //   { offset: "+06", name: "BST" },
  //   { offset: "+0630", name: "MYST" },
  //   { offset: "+07", name: "ICT" },
  //   { offset: "+08", name: "SGT" },
  //   { offset: "+09", name: "JST" },
  //   { offset: "+10", name: "ChST" },
  //   { offset: "+11", name: "SBT" },
  //   { offset: "+12", name: "FJT" },
  //   { offset: "+14", name: "SST" },
  // ]
  // offsetArray.forEach(zone => {
  //   if (zone.offset === standardAbbr) standardAbbr = zone.name;
  // });
  switch (standardAbbr) {
    case "PST":
      return "PT";
    case "PDT":
      return "PT";
    case "EST":
      return "ET";
    case "EDT":
      return "ET";
    default:
      return standardAbbr;
  }
}
