import React from "react";
// import FormBuilder from "../forms/FormBuilder";
import {FormBuilder} from "@jaguardesignstudio/silentgrowl-form-builder-react";
import RegistrationCustomButton from "../../../registration/RegistrationCustomButton";
import RegistrationCancelButton from "../../../registration/RegistrationCancelButton";

class AdminParticipantFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.callback = this.callback.bind(this);
  }

  callback(json) {
    const { updateParticipantCallback } = this.props;
    console.log('manual reg submit complete');
    console.log(json);
    updateParticipantCallback(json.participants);
  }

  getReplacableStrings() {
    return [
      {key: '*|EMAIL|*', value: 'participant.email', from: 'formik_values' }
    ]
  }

  render() {
    const { ticket_info, participant, form, toggleModal } = this.props;
    return (
      <FormBuilder
        key="registration-form"
        components={{
          buttonCustom: RegistrationCustomButton,
          buttonCancel: RegistrationCancelButton
        }}
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/admin/ticketing/update_user'}
        submitCallback={this.callback}
        type={'registration'}
        hide_test={false}
      />
    );
  }
}

export default AdminParticipantFormWrapper;
