import React from "react";

class RegistrationCancelButton extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }
  
  render() {
    const { question } = this.props;
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`}>
        <div onClick={() => { location.reload(); } }>
          <button className="sg-button sg-submit-button reg-button">
            {question.label}
          </button>
        </div>
      </div>
    )
  }
}

export default RegistrationCancelButton;
