import React from "react";

class LandingFaqItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  toggleIsOpen() {
    const { isOpen } = this.state;
    this.setState({isOpen: !isOpen});
  }

  renderDescription() {
    const { item, overnight } = this.props;
    return (
      <div className={`landing-faq-item-description ${overnight ? 'landing-faq-item-description-overnight' : ''}`} dangerouslySetInnerHTML={{__html: item.answers.answer}}/>
    );
  }

  render() {
    const { item, overnight } = this.props;
    const { isOpen } = this.state;
    return (
      <div className={`landing-faq-item-wrapper ${overnight ? 'landing-faq-item-wrapper-overnight' : ''}`}>
        <div className="landing-faq-item-header" onClick={this.toggleIsOpen}>
          <div dangerouslySetInnerHTML={{__html: item.answers.question}} />
          <div>
            <img src={`/images/landing/faq/ico-faq-${isOpen ? 'close' : 'open'}${overnight ? '-white' : ''}.svg`}/>
          </div>
        </div>
        {
          isOpen ?
          this.renderDescription()
          :
          <></>
        }
      </div>
    );
  }
}

export default LandingFaqItem;
