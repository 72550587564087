export function getCopy(cms_item, opts ={}) {
  //TODO Language Split
  if(cms_item){
    return cms_item['answers']['english-content-1']
  }
  return opts["default"]||""
}

export function getTitle(cms_item, opts ={}) {
  //TODO Language Split
  if(cms_item){
    return cms_item['answers']['title']
  }
  return opts["default"]||""
}

export function getJSONOptions(cms_item, opts ={}) {
  //TODO Language Split
  if(cms_item){
    return JSON.parse(cms_item['answers']['options']);
  }
  return opts["default"]||""
}

export function getAllowGuestOptions(cms_item, opts ={}) {
  //TODO Language Split
  if(cms_item){
    return cms_item['answers']['allow-guest'];
  }
  return opts["default"]||""
}
