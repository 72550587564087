import React from "react";

class DefaultHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  renderDropdownIcon() {
    const { isOpen } = this.state
    return (
      <img onClick={this.toggleOpen} src={`/images/landing/${isOpen ? "menuclose.png" : "menuopen.png"}`} className={`sg-landing-page-mobile-menu-dropdown-icon ${isOpen ? "open" : "closed"}`} alt=""/>
    )
  }

  renderDesktopHeader() {
    const { overnight } = this.props;
    return (
      <nav class="navbar navbar-inverse header-desktop" role="navigation">
        <div className={`landing-page-wrapper ${overnight ? 'landing-page-wrapper-overnight' : ''}`} style={{padding: "0px"}}>
          <div className="container sg-f1-header-container" style={{marginBottom: '-1px'}}>
            <div className="row equal">
              <div className="col-xs-6" style={{alignContent: "center", display: "flex"}}>
                {
                  overnight ?
                  <img src="/images/header/header-lockup-overnight.svg" />
                  :
                  <img src="/images/header/header-lockup.svg" />
                }
              </div>
              <div className="col-xs-6" style={{alignContent: "center", display: "flex", justifyContent: "end"}}>
                {
                  overnight ?
                  <img src="/images/header/header-racecar-overnight.svg" />
                  :
                  <img src="/images/header/header-racecar.svg" />
                }
              </div>
            </div>
          </div>
        </div>
        <div className={`landing-page-wrapper ${overnight ? 'landing-page-subhead-overnight' : 'landing-page-subhead'}`}>
          <div className="container sg-f1-header-container" style={{maxWidth: "700px"}}>
            <div className="row">
              <div className="col-xs-12">
                <div className="sg-f1-header-text">
                  SINGAPORE GRAND PRIX 2024
                  <br />
                  <span>MARINA BAY STREET CIRCUIT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }

  renderMobileHeader() {
    const { overnight } = this.props;
    return (
      <nav class="navbar navbar-inverse header-mobile" role="navigation" style={{marginTop:"90px", marginBottom: "0px"}}>
        <div className="landing-page-wrapper" style={{backgroundColor: `${overnight ? '#E2E2E2' : '#0D274D'}`, padding: "20px 0px 15px"}}>
          <div className="container sg-f1-header-container">
            <div className="row">
              <div className="col-xs-12">
                <div className="sg-f1-header-text" style={{color: `${overnight ? '#0D274D' : '#00bceb'}`}}>
                  SINGAPORE GRAND PRIX 2024
                  <br />
                  <span style={{color: `${overnight ? '#FF7A00' : '#ffffff'}`}}>MARINA BAY STREET CIRCUIT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }

  renderMenu() {
    const { isOpen } = this.state
    return (
      <div className="landing-page-header-mobile-menu">
        {isOpen ? (
          this.renderMenuInner()
        ):""}
      </div>
    )
  }

  renderMenuInner() {
    const { overnight } = this.props;
    return (
      <div class="landing-page-header-nav-wrapper">
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="home_nav">
            <a href="#home">
            Home
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="schedule_nav">
            <a href="#schedule">
            Schedule
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="parking_nav">
            <a href="#parking">
            Arrival &amp; Check-In
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="ticket_nav">
            <a href="#ticket">
            Your Tickets
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="shuttle_nav">
            <a href="#shuttle">
            Shuttle
            </a>
        </div>
        {
          overnight ?
          <></>
          :
          <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="gate_nav">
            <a href="#gate">
              Gate Times
            </a>
          </div>
        }

        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="security_nav">
            <a href="#security">
            Security
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="faqs_nav">
            <a href="#faqs">
            FAQs
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="contact_nav">
            <a href="#contact">
            Contact Us
            </a>
        </div>
      </div>
    )
  }

  render() {
    const { overnight } = this.props;
    return (
      <>

        <div className="navbar-mobile-landing-page-addition" style={ overnight ? {backgroundColor: "#0D274D"} : {}}>
          <div className="container sg-f1-header-container">
            <div className="row">
              <div className="col-xs-12" style={{alignContent: "center"}}>
                {
                  overnight ?
                  <img src="/images/header/header-lockup-overnight.svg" style={{maxWidth: "184px", margin: "20px 0px"}}/>
                  :
                  <img src="/images/header/header-lockup.svg" style={{maxWidth: "184px", margin: "20px 0px"}}/>
                }
              </div>
            </div>
          </div>

          {/* {this.renderDropdownIcon()} */}
          {/* {this.renderMenu()} */}
        </div>
        {this.renderDesktopHeader()}
        {this.renderMobileHeader()}

      </>
    )

  }
}

export default DefaultHeader;
