import React from "react";
import PropTypes from "prop-types";
class RegistrationTicketField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  getFormikValue(attribute_id, formikProps) {
    const splitQuestionId = attribute_id.split('.');
    let formikPropsParticipant = formikProps.values["participant"];
    return formikPropsParticipant["metadata"][splitQuestionId[2]];
  }

  setFormikValue(value, attribute_id, formikProps) {
    const splitQuestionId = attribute_id.split('.');
    let formikPropsParticipant = formikProps.values["participant"];
    formikPropsParticipant["metadata"][splitQuestionId[2]] = value;
    formikProps.setFieldValue('participant', formikPropsParticipant);
  }

  renderTicketName(ticket) {
    if (ticket.slug === 'day1') {
      return (
        <>
          <b>Thursday</b>
          <br />
          <b>June 13</b>
          <br />
          <span style={{fontSize: "12px"}}>First Round</span>
        </>
      )
    }
    if (ticket.slug === 'day2') {
      return (
        <>
          <b>Friday</b>
          <br />
          <b>June 14</b>
          <br />
          <span style={{fontSize: "12px"}}>Second Round</span>
        </>
      )
    }
    if (ticket.slug === 'day3') {
      return (
        <>
          <b>Saturday</b>
          <br />
          <b>June 15</b>
          <br />
          <span style={{fontSize: "12px"}}>Third Round</span>
        </>
      )
    }
    if (ticket.slug === 'day4') {
      return (
        <>
          <b>Sunday</b>
          <br />
          <b>June 16</b>
          <br />
          <span style={{fontSize: "12px"}}>Championship Round</span>
        </>
      )
    }
  }

  render() {
    const { page, regPages, question, formikProps, ticket_info, checkError } = this.props;
    let value = this.getFormikValue(question.attribute_id, formikProps);
    const renderedDates = ticket_info.sort((a,b)=>a.id - b.id).map(ticket=> (
      <div onClick={()=> {
        if(ticket.available) {
          const { participant } = formikProps.values;
          this.setFormikValue(ticket.slug, question.attribute_id, formikProps);
        }
      }} disabled={!ticket.available} key={`ticket-field-${ticket.id}`} className={`sg-masters-date-select-item ${value == ticket.slug ? "active" : ""} ${!ticket.available ? "disabled" : ""} `}>
        <div className="sg-masters-date-select-item-inner">
          <img src={`/registration/days/${ticket.slug}${value == ticket.slug ? "" : "-active"}.svg`} className="sg-masters-date-select-item-image"/>
          <div className="sg-masters-date-select-item-copy" style={{textAlign: "center"}}>
            {this.renderTicketName(ticket)}
          </div>
          <img src={`/registration/days/checkmark.png`} className="sg-masters-date-select-checkmark-image"/>
          <div className="sg-masters-date-select-item-disabled-message">
            No longer available
          </div>
        </div>
      </div>
    ))
    return (
      <>
      <div className="col-xs-12">
        <div className="sg-masters-date-select-item-container">
          {renderedDates}
        </div>
        <div className="registration-field-error">
          {checkError && ticket_info.map(ticket => ticket.slug).indexOf(value) == -1 ? "Please select an option to continue." : ""}
        </div>
      </div>
      <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
      </div>
      </>
    )
  }
}

export default RegistrationTicketField;
