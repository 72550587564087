import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select'
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
class SurveysHostIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageOneError: null,
      pageThreeError: null,
      pageTwoError: null,
      checkError: false,
      emailTaken: false,

      dietaryRestrictions: [],
    }
    this.formMethod  = this.formMethod.bind(this);
    this.formURL = this.formURL.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.checkEmailTaken = this.checkEmailTaken.bind(this);
    this.setFilesQuestion = this.setFilesQuestion.bind(this);
  }

  setFiles(name, file, preview, tagList) {
    this.setState({
      projectImage: file,
      previewImage: preview
    });
  }

  setFilesQuestion(name, file, preview, tagList) {
    this.setState({
      questionImage: file,
    });
  }

  pageHiddenClass(renderedPage) {
    const { page } = this.state;
    if(renderedPage == page) {
      return "visible";
    }
    return "hidden";
  }

  isEmailPersonal(email) {
    // const personalEmails = [
    //   "@jaguardesignstudio",
    // ];
    // if(email &&  this.includesPolyfill(email.toLowerCase(), ".") && this.includesPolyfill(email.toLowerCase(), "@") && !personalEmails.filter(personalEmail => (this.includesPolyfill(email.toLowerCase(),personalEmail))).length > 0) {
    //   return true;
    // }
    return false;
  }

  includesPolyfill(string1, string2) {
    return string1.indexOf(string2) != -1
  }

  isRadioBlockErroring(formikProps) {
    const { values } = formikProps;
    let fieldList = this.radioBlockOptions().filter(x=>!x.no_options).map(x=>x.value)
    console.log(fieldList);
    const validFields = fieldList.filter(field => values["survey"][field] && values["survey"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return true;
    }
    return false
  }

  isPageOneValid(formikProps) {
    const { values } = formikProps;
    let fieldList = [
      "name_first",
      "name_last",
      "job_title",
      "satisfied",
      "cisco_rating"
    ];

    const validFields = fieldList.filter(field => values["survey"][field] && values["survey"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }

    if(this.isRadioBlockErroring(formikProps)) {
      return false;
    }

    return true;
  }


  checkEmailTaken(formikProps){
    this.setState({
      emailTaken: false,
    });

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var form_data = new FormData();
    form_data.append("email", formikProps.values.survey['email']);


    fetch('/registration/check_email_taken', {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        if(json.taken){

          this.setState({
            emailTaken: true,
          })
        }
      }
    });

  }


  renderPageOne(formikProps) {
    const { pageOneError, emailTaken, checkError, pageThreeError, fullPageError } = this.state;
    return (
      <>

      <div className={`registration-page-one container sg-survey-wrapper ${this.pageHiddenClass(1)}`}>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <label className="" style={{fontSize:"18px"}}>
            Thank you for your contribution in support of the 2022 United States Grand Prix in Canada. Please take a moment to share your thoughts with us. Your feedback helps guide decisions for future experiences.
            </label>
          </div>
          <div className="col-xs-12">
            <label className="required"> How satisfied were you with the overall program experience for the customers?</label>
            {this.renderCustomRadioField('satisfied',formikProps, this.satisfactionOptions())}
            <div className="registration-field-error">
              {checkError && !this.isPageOneValid(formikProps) && !(formikProps.values.survey.satisfied)? "Please select an option to continue." : ""}
            </div>
          </div>

          <div className="col-xs-12">
            <label className="required">As a result of your customer attending the United States Grand Prix in Canada, please rate the value of this opportunity in helping drive business for Cisco.</label>
            {this.renderCustomRadioField('cisco_rating',formikProps, this.overallCisco())}
            <div className="registration-field-error">
              {checkError && !this.isPageOneValid(formikProps) && !(formikProps.values.survey.cisco_rating)? "Please select an option to continue." : ""}
            </div>
          </div>

          <div className="col-xs-12 col-sm-12">
            <label className="required">
              Please rate the following:
            </label>
          </div>

          <div className="col-xs-12">
            {this.renderRadioBlock('',formikProps, this.overallExperience(), this.radioBlockOptions())}
          </div>
          <div className="col-xs-12 registration-field-error">
            {checkError && this.isRadioBlockErroring(formikProps) ?  "Please rate all of the above to continue." : ""}
          </div>

          <div className="col-xs-12">
            <label className="">If given the opportunity, would you attend this experience again?</label>
          </div>
          <div className="col-xs-12 col-sm-8">
          {this.renderCustomRadioField('attend_again',formikProps, this.attendAgainOptions())}
          </div>

          <div className="col-xs-12" style={{marginBottom: '20px'}}>
            <label className=""> What was your favorite part of the experience? </label>
            {this.renderField('favortite_part',formikProps, {style: {minHeight: "120px"}, optional: true, component: 'textarea'})}
          </div>
          <div className="col-xs-12" style={{marginBottom: '20px'}}>
            <label className=""> What part of the experience, if any, has room for improvement? </label>
            {this.renderField('room_for_improvement',formikProps, {style: {minHeight: "120px"}, optional: true, component: 'textarea'})}
          </div>
          <div className="col-xs-12" style={{marginBottom: '20px'}}>
            <label className=""> Please share any additional comments. </label>
            {this.renderField('notes',formikProps, {style: {minHeight: "120px"}, optional: true, component: 'textarea'})}
          </div>

          <div className="col-xs-12 col-sm-6">
            <label className="required" > First Name </label>
            {this.renderField('name_first',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6" style={{marginBottom: '10px'}}>
            <label className="required" > Last Name </label>
            {this.renderField('name_last',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6" style={{marginBottom: '10px'}}>
            <label className="" > Title </label>
            {this.renderField('job_title',formikProps)}
          </div>

          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageOneValid(formikProps) ? pageThreeError : ""}
              {fullPageError}
            </div>
            <button style={{marginBottom:"50px", backgroundColor: "#00bceb"}} className="registration-next-button" type="button" onClick={() => this.submitFormButtonOnClick(formikProps)}>
              Submit
            </button>
          </div>
        </div>
      </div>
      </>
    )
  }

  overallExperience() {
    return [
      "Excellent",
      "Above Average",
      "Average",
      "Below Average",
      "Poor"
    ]
  }

  radioBlockOptions() {
    return [
      {label: "Overall Experience", value: "experience_rating"},
      {label: "Amenities", value: "amenities"},
      {label: "Transportation", value: "transportation" },
      {label: "Accommodations at the Four Seasons", value: "hotel" },
      {label: "Cisco Hospitality Lounge", value: "hospitality_lounge" },
      {label: "Cisco x McLaren Business Session", value: "networking_lunch"},
      {label: "McLaren Paddock Club", value: "mclaren_paddock_club" },
      {label: "Pit Lane Walk", value: "pit_lane_walk" },
      {label: "Garage Tour", value: "garage_tour" },
      {label: "Race Watch", value: "race_watch" },
      {label: "Local Activities", value: "local_activities"},
      {label: "Evening Experiences", value: "evening_experiences"},
    ]
  }

  overallCisco() {
    return [
      "Positive",
      "Slightly Positive",
      "Neutral",
      "Slightly Negative",
      "Negative"
    ]
  }

  attendAgainOptions() {
    return [
      "Definitely",
      "Likely",
      "Maybe",
      "No"
    ]
  }

  renderCustomRadioField(name,formikProps, options, style={}) {
    const { values } = formikProps;
    const renderedOptions = options.map(option =>{

      return (
        <label class="custom-container">
          {option}
          <input
            type="radio"
            name={"survey-"+name}
            value={option}
            checked={values.survey[name] === option}
            onChange={() => {
              const { survey } = formikProps.values;
              survey[name] = option
              formikProps.setFieldValue('survey', survey)
            }}
          />
          <span class="checkmark"/>
        </label>
      );
    })

    return (
      <div className="sg-survey-options-container" style={{display: style['display']||'flex', justifyContent: 'space-between'}}>
        {renderedOptions}
      </div>
    );
  }

  renderRadioBlock(name,formikProps, options, radioOptions) {
    return (
      <table className="radio-block-table" style={{marginBottom: '15px'}}>
        <tr>
          <th></th>
          {options.map(option => <th className="sg-survey-radio-block-header-options">{option}</th>)}
        </tr>
        {
          radioOptions.map(option => <tr>{this.renderRadioFieldBlock(option.value, formikProps, options, option.label, option)}</tr>)
        }
      </table>
    );
  }

  renderRadioFieldBlock(name, formikProps, options, label, option) {
    const { values } = formikProps;
    const renderedOptions = options.map(option =>{
      return (
        <td className="radio-block-td">
          <label class="custom-container">
            <span className="show-mobile">{option}</span>
            <input
              type="radio"
              name={"survey-"+name}
              value={option}
              checked={values.survey[name] === option}
              onChange={() => {
                const { survey } = formikProps.values;
                survey[name] = option
                formikProps.setFieldValue('survey', survey)
              }}
            />
            <span class="checkmark"/>
          </label>
        </td>
      );
    })
    return (
      <>
      <td>
        <label className={option.optionClass||""}>{label}</label>
      </td>
      {option.no_options ? "" : renderedOptions}
      </>
    );
  }

  renderRadioField(name,formikProps, options) {
    const { values } = formikProps;
    const renderedOptions = options.map(option =>{

      return (
        <div className="sg-survey-option-box-container">
          <label>
           <input
             type="radio"
             name={"survey-"+name}
             value={option}
             checked={values.survey[name] === option}
             onChange={() => {
               const { survey } = formikProps.values;
               survey[name] = option
               formikProps.setFieldValue('survey', survey)
             }}
           />{option}
         </label>
        </div>
      )
    })

    return (
      <div className="sg-survey-options-container">
        {renderedOptions}
      </div>
    );
  }

  renderField(name,formikProps,  properties = {}) {
    const { checkError } = this.state;
    const customValidation = false;
    let erroring = checkError && (formikProps.values.survey[name].length <= 0 || customValidation);
    if(properties['optional']) {
      erroring = false;
    }
    return (
      <Field
        onKeyUp={() => properties['onKeyUp'](formikProps)}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`}
        type={properties['type']} name={`survey[${name}]`}
        style={{...(properties['style']||{})}}
      />
    )
  }


  submitFormButtonOnClick(formikProps) {
    const { values } = formikProps;
    let fieldListPart1 = [
      "name_first",
      "name_last",
    ];

    const validFieldListPart1 = fieldListPart1.filter(field => values["survey"][field] && values["survey"][field].length >= 1);

    if(validFieldListPart1.length != fieldListPart1.length) {
      this.setState({
        pageThreeError: "Please make sure all fields are filled out correctly",
        checkError: true
      })
    }
    else {
       this.setState({
         pageThreeError: null,
         checkError: true,
         fullPageError: null,
       })
       formikProps.handleSubmit()
     }
  }



  formMethod() {
    return 'POST'
  }

  formURL() {
    return `/surveys`
  }

  satisfactionOptions() {
    return [
      "Satisfied",
      "Slightly Satisfied",
      "Neutral",
      "Slightly Dissatisfied",
      "Dissatisfied"
    ]
  }

  render() {
    const { survey } = this.props;
    return (
      <div className="registration-form">
        <Formik
          initialValues={{
            survey: {
              uuid: "host",
              experience_rating:"",
              amenities:"",
              transportation:"",
              club_at_hotel_arts:"",
              mclaren_paddock_club:"",
              pit_lane_walk:"",
              garage_tour:"",
              networking_lunch:"",
              mclaren_driver_meet_and_greet:"",
              mclaren_business_lunch:"",
              group_river_cruise:"",
              race_day_brunch:"",
              satisfied:"",
              cisco_rating:"",
              attend_again:"",
              favortite_part:"",
              room_for_improvement:"",
              notes:"",
              name_first:"",
              name_last:"",
              job_title:"",
              local_activities:"",
              evening_experiences:""
            },
          }}

          onSubmit={(values, actions) => {
            const { projectImage, questionImage } = this.state;
            const cookies = new Cookies();
            const token = cookies.get("X-CSRF-Token");

            var form_data = new FormData();

            values['survey']['name'] = `${values['survey']['name_first']} ${values['survey']['name_last']}`

            for ( var key in values['survey'] ) {
              form_data.append(`survey[${key}]`, values['survey'][key]);
            }


            fetch(this.formURL(), {
              method: this.formMethod(),
              redirect: "manual",
              body: form_data,
              headers: {
                "X-CSRF-Token": token,
              }
            })
              .then(response => {
                try {
                  return response.json();
                }
                catch(e) {
                  window.location.href="/surveys/complete";
                }
              })
              .then(json => {
                if (json.error == null) {
                  window.location.href = `/surveys/complete`;
                } else {
                  this.setState({
                    fullPageError: json.error
                  })
                }
              });
          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0"}}>
              {this.renderPageOne(formikProps)}
            </Form>
        )}/>
      </div>
    );
  }
}

export default SurveysHostIndex;
